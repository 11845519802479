import { fromSearchFore,contractAddress } from '@/const/publicData/record'
export const topUp_ = [{
    type: 'input',
    name: 'ID',
    prop: 'recordId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '客户地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'N作品名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'confirmTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const withdrawCash_ = [{
    type: 'input',
    name: 'ID',
    prop: 'applyId',
},{
    type: 'input',
    name: '交易ID',
    prop: 'transactionId',
},{
    type: 'select',
    name: '审核状态',
    prop: 'status',
    postData: [{ label: '待审核', value: 0 },{label: '审核通过',value:1},{label: '审核拒绝',value:2}],
},
...fromSearchFore,
...contractAddress,
{
    type: 'input',
    name: '客户ID',
    prop: 'applyUser',
},
{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
}, {
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '交易时间',
    prop: 'withdrawTime',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'applyTime',
},{
    type: 'select',
    name: '状态',
    prop: 'step',
    postData: [{ label: '待确认', value: 1 },{label: '已确认',value:2},{label: '确认失败',value:3}],
},]
export const walletWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'input',
    name: '客户地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: 'From',
    prop: 'fromAddress',
},{
    type: 'input',
    name: 'To',
    prop: 'toAddress',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'input',
    name: 'TXID',
    prop: 'txid',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]
export const accountWater_ = [{
    type: 'input',
    name: '变动ID',
    prop: 'changeLogId',
},
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'input',
    name: '系列名称',
    prop: 'collectionName',
},{
    type: 'input',
    name: 'NFT名称',
    prop: 'itemName',
},{
    type: 'input',
    name: 'TokenId',
    prop: 'tokenId',
},{
    type: 'datetime',
    name: '变动时间',
    prop: 'createTime',
},
]
export const balanceWallet_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const balanceAccount_ = [
    ...fromSearchFore,
{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'datetime',
    name: '更新时间',
    prop: 'updateTime',
},]
export const address_ = [
    ...fromSearchFore,
    ...contractAddress,
{
    type: 'input',
    name: '钱包地址',
    prop: 'walletAddress',
},{
    type: 'input',
    name: '客户ID',
    prop: 'userId',
},{
    type: 'datetime',
    name: '创建时间',
    prop: 'createTime',
},]